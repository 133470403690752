
body{
    --primary-color: #0457BC;
    --bg-color: #fff;
    --header-color: #353D42;
    --text-color: #353D42;
    --hover-color: #e0e0e052;
    --body-color: #F3F6F8;
    --parent-link-color: #86A1B1;
    --child-link-color: #353D42;
    --border-color: #ddd;
    --side-bar-color: #C6E6FC;
    --toogle-color: #e8edf1;
    --error-color: red;
    --header-bg: #f3f6f8;
    --active-color: #22947c;
    --pending-color: #E37400;
    --fab-color: gray;
    --carousel-color : #7baff1;
    --list-color: rgba(227, 232, 238, 0.20);
    --other-text: #86A1B1;
    --menu-bar-color: #0B396D;
    --cancel-color: #E2425C;
    --empty-color: #C2C7CF;
    --opacity-color: #A3ACB9;
    --opacity-color-1 : #dddd;
    --top-color: #F7FAFC;
    --chat-backgroud: #CCDAE0;
}
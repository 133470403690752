@import "@angular/material/theming";
@import "./assets/scss/custom.scss";
@import "./assets/scss/popup.scss";

// @font-face {
//   font-family: Karla;
//   src: url("./assets/fonts/Karla/static/Karla-Regular.ttf")format("opentype");
//   font-weight: 500;
// }
// @font-face {
//   font-family: Karla;
//   src: url("./assets/fonts/Karla/static/Karla-Regular.ttf")format("opentype");
//   font-weight: bold;
// }
// @font-face {
//   font-family: Karla;
//   src: url("./assets/fonts/Karla/static/Karla-Regular.ttf")format("opentype");
//   font-weight: normal;
// }
// @font-face {
//   font-family: Karla;
//   src: url("./assets/fonts/Karla/static/Karla-Regular.ttf")format("opentype");
//   font-weight: 600;
// }

html,
body {
    overflow-x: hidden;
    height: 100%;
}
body {
    margin: 0;
    font-family: "Proxima Nova", sans-serif;
}

@font-face {
    font-family: nunito;
    src: url(./assets/fonts/Nunito-SemiBold.ttf) format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: nunito;
    src: url(./assets/fonts/Nunito-Bold.ttf) format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: nunito;
    src: url(./assets/fonts/Nunito-ExtraBold.ttf) format("opentype");
    font-weight: 600;
}

:root {
    --primary-color: #1976d2; /* Default color */
}

$convz-brand: (
    50: #e5f6f2,
    100: #bde9df,
    200: #92daca,
    300: #0457bc,
    400: #0457bc,
    500: #0457bc,
    600: #0457bc,
    700: #0457bc,
    800: #0457bc,
    900: #0457bc,
    A100: #bdffe9,
    A200: #8affd9,
    A400: #045732,
    A700: #0457bc,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text
    )
);

$convz-accent: (
    50: #ffffff,
    100: #dde6f3,
    200: #b4c9e4,
    300: #7fa3d1,
    400: #6992c9,
    500: #5282c1,
    600: #4072b4,
    700: #38649d,
    800: #305687,
    900: #284770,
    A100: #ffffff,
    A200: #dde6f3,
    A400: #0457ae,
    A700: #0457bc,
    // #779a1e,
    contrast:
        (
            50: white,
            100: $black-87-opacity,
            200: $black-87-opacity,
            300: $black-87-opacity,
            400: $black-87-opacity,
            500: white,
            600: white,
            700: white,
            800: white,
            900: white,
            A100: $black-87-opacity,
            A200: $black-87-opacity,
            A400: $black-87-opacity,
            A700: white
        )
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$convz-primary: mat-palette($convz-brand, A700);
$convz-accent: mat-palette($convz-accent, A400);

body {
    padding: 0;
    margin: 0;
}

// The warn palette is optional (defaults to red).
$convz-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$convz-theme: mat-light-theme($convz-primary, $convz-accent, $convz-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($convz-theme);

$primary: map-get($convz-primary, A700);
$secondary: map-get($convz-theme, accent);
$white: map-get($convz-theme, white);
$warn: map-get($convz-theme, warn);

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
